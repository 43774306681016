<template>
  <div class="intro">
    <div class="grid grid--middle">
      <div class="grid__item md-w-1/2">
        <h1 class="mrg0 mrgb++"><span>{{ text }}</span></h1>
        <ul class="list flex">
          <li><a href="https://github.com/dulnan" class="link mrgr-">GitHub</a></li>
          <li><a href="https://codepen.io/dulnan" class="link mrgr-">CodePen</a></li>
          <li><a href="https://twitter.com/dulnan" class="link mrgr-">Twitter</a></li>
          <li><a href="mailto:me@dulnan.net" class="link">E-Mail</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.intro {
  position: relative;
  z-index: 10;
  padding-top: 15rem;
  @include media('md') {
    height: 50vh;
    display: flex;
    align-items: center;
    padding-top: 0;
  }

  a {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    padding-bottom: 3px;
    &:hover {
      border-bottom: 2px solid white;
    }
  }
}
</style>

<template>
  <div class="about">
    <div class="grid grid--multiline">
      <div v-for="fact in facts" :key="fact.title" class="grid__item md-w-1/2">
        <fact :fact="fact" />
      </div>
    </div>
  </div>
</template>

<script>
import Fact from '@/components/Fact.vue'

export default {
  components: {
    Fact
  },

  props: {
    facts: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.about {
  position: relative;
  @include media('md') {
    padding-top: 10rem;
  }
}

.about > .grid {
  // Calculate the spacing between the absolute positioned fact images.
  $total: 8;

  @for $i from 0 to $total {
   $calc-md: calc((((100vw - #{2 * $gutter-md}) - 100%) / #{$total - 1}) * #{$i});
   $calc-lg: calc((((#{$screen-lg-min} - #{2 * $gutter-lg}) - 100%) / #{$total - 1}) * #{$i});

    > .grid__item:nth-child(#{$i + 1}) .fact__aside {
      @include media('md') {
        transform: translateX(#{$calc-md});
      }
      @include media('lg') {
        transform: translateX(#{$calc-lg});
      }
    }
  }
}
</style>

<template>
  <div class="item-content">
    <div class="item-content__header">
      <h3>
        <span>{{ title }}</span>
      </h3>
      <div class="mrgl--" v-if="type || tasks">
        <span class="tag is-filled mrgr--" v-if="type">{{ type }}</span>
        <span v-for="task in tasks" :key="task" class="tag mrgr--">{{ task }}</span>
      </div>
    </div>

    <p class="mrgba">{{ text }}</p>

    <link-list :links="links" />
  </div>
</template>

<script>
import LinkList from '@/components/LinkList.vue'

export default {
  components: {
    LinkList
  },

  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: false
    },
    tasks: {
      type: Array,
      required: false
    },
    technologies: {
      type: Array,
      required: false
    }
  }
}
</script>

<style lang="scss">
.item-content {
  display: flex;
  flex-direction: column;
}

.item-content__header {
  display: flex;
}
</style>

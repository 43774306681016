import Vue from 'vue'
import AppMain from './AppMain.vue'
import AppScene from './AppScene.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(AppMain)
}).$mount('#app-main')

new Vue({
  render: h => h(AppScene)
}).$mount('#app-scene')

<template>
  <div class="grid grid--multiline" :class="'projects--' + size">
    <div
      class="grid__item"
      :class="getItemClasses(size)"
      v-for="item in items"
      :key="item.title"
    >
      <item
        :item="item"
        :size="size"
        :link="getMainLink(item.links)"
      />
    </div>
  </div>
</template>

<script>
import Item from '@/components/Item.vue'

export default {
  components: {
    Item
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },

  methods: {
    getMainLink (links) {
      if (!links.length === 0) {
        return '/'
      }
      return links[0].link
    },

    getItemClasses (size) {
      switch (size) {
        case 'featured':
          return 'w-1/1'
        case 'large':
          return 'sm-w-1/2'
        case 'medium':
          return 'sm-w-1/2 lg-w-1/3'
        case 'small':
          return 'md-w-1/2'
      }
    }
  }
}
</script>

<style lang="scss">
.projects--large {
  position: relative;
  z-index: 9;
}
</style>

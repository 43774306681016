<template>
  <div id="app-main" data-server-rendered="true">
    <page-section>
      <intro :text="lead" />
    </page-section>

    <page-section heading="Websites">
      <list-small :items="featured" size="featured" class="mrgb++" v-if="featured" />
      <list-small :items="websites" size="large" />
    </page-section>

    <page-section heading="Open Source">
      <list-small :items="openSource" size="small" />
    </page-section>

    <page-section heading="Experiments">
      <list-small :items="demosExperiments" size="medium" />
    </page-section>

    <page-section heading="About Me">
      <about-me :facts="facts" />
    </page-section>
  </div>
</template>

<script>
import './assets/scss/main.scss'

import PageSection from './components/PageSection.vue'
import AboutMe from './components/AboutMe.vue'
import Intro from './components/Intro.vue'
import ListSmall from './components/ListSmall.vue'

import data from './data/content.json'

export default {
  name: 'app',
  components: {
    PageSection,
    ListSmall,
    AboutMe,
    Intro
  },

  data () {
    return {
      appsServices: [],
      demosExperiments: [],
      openSource: [],
      featured: [],
      websites: [],
      lead: '',
      text: '',
      facts: [],
      socialLinks: [
        {
          name: 'GitHub',
          url: '/',
          icon: 'GitHub'
        },
        {
          name: 'CodePen',
          url: '/',
          icon: 'CodePen'
        },
        {
          name: 'Twitter',
          url: '/',
          icon: 'Twitter'
        }
      ]
    }
  },

  mounted () {
    this.appsServices = data.projects.appsServices
    this.demosExperiments = data.projects.demosExperiments
    this.openSource = data.projects.openSource
    this.websites = data.projects.websites
    this.featured = data.projects.featured

    this.lead = data.content.lead
    this.text = data.content.text

    this.facts = data.facts
    document.dispatchEvent(new Event('render-event'))
    if (window) {
      this.$nextTick(function () {
        document.body.classList.remove('loading')

        window.setTimeout(function () {
          document.body.classList.remove('transition')
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss">
#app-main {
}
</style>

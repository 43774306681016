<template>
  <ul class="list link-list flex">
    <li v-for="link in links" :key="link.link">
      <a :href="link.link" class="link">{{ link.type }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.link-list {
  margin-top: 1rem !important;
  > li {
    margin-right: 0.75rem;
    @include type (14, 16);
  }
  a {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.04em;
    display: inline-block;
  }
}
</style>

<template>
  <a
    class="item__image material block"
    :class="{ 'hover': isHovered }"
    :href="link"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
    @touchcancel="handleTouchCancel"
  >
    <responsive-image :hash="hash" :size="size" :file-type="getFileType(size)" class="image--color" />
    <responsive-image :hash="hash" :size="size" :file-type="getFileType(size)" class="image--inverted" />
  </a>
</template>

<script>
import ResponsiveImage from '@/components/ResponsiveImage.vue'

export default {
  components: {
    ResponsiveImage
  },

  props: {
    link: {
      type: String,
      default: ''
    },
    hash: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },

  data () {
    return {
      isHovered: false
    }
  },

  methods: {
    getFileType (size) {
      if (size === 'small') {
        return 'png'
      }

      return 'jpg'
    },
    handleTouchStart () {
      this.isHovered = true
    },

    handleTouchEnd () {
      this.isHovered = false
    },

    handleTouchCancel () {
      this.isHovered = false
    }
  }
}
</script>

<style lang="scss">
.item__image {
  flex: 0 0 auto;
  position: relative;
  transition: 0.5s;
  &:hover {
    // transform: translateZ(-2rem);
  }
}
</style>

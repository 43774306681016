<template>
  <div class="image" :class="'image--' + size">
    <img :src="getFallbackSrc(hash)" :srcset="getSrcset(hash)">
  </div>
</template>

<script>
import stacks from '@/data/stacks.json'

export default {
  props: {
    hash: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    },
    fileType: {
      type: String,
      default: 'jpg'
    }
  },

  methods: {
    getSrcset (hash) {
      let urls = []
      stacks[this.size].forEach(stack => {
        urls.push(`${this.buildRokkaUrl(stack.name, hash)} ${stack.width}w`)
      })

      return urls.join(', ')
    },

    getFallbackSrc (hash) {
      const fallbackStack = stacks[this.size][stacks[this.size].length - 1]
      return this.buildRokkaUrl(fallbackStack.name, hash)
    },

    buildRokkaUrl (stack, hash) {
      return `https://janhug.rokka.io/${stack}/${hash}.${this.fileType}`
    }
  },

  mounted () {
  }
}
</script>

<style lang="scss">
.image {
  height: auto;
  max-width: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.image--tiny {
  max-width: 2rem;
  max-height: 2rem;
  @include media('md') {
    max-width: 4rem;
    max-height: 4rem;
  }
}

.image--small {
  max-width: 5rem;
  max-height: 5rem;
  @include media('md') {
    max-width: 7rem;
    max-height: 7rem;
  }
}

.image--medium {
  max-width: rem(660);
  max-height: 32rem;
}

.image--large {
  max-width: rem(664);
  max-height: rem(494);
}

.image--featured {
  width: rem(1376);
  max-height: rem(1024);
}

.image--inverted {
  background: black;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #383838;
    z-index: 5;
  }
}

.image--color {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  transition: 0.5s;
  will-change: opacity;
  transform: translate3d(0,0,0);

  @include media('md') {
    .item:hover & {
      opacity: 1;
    }
  }
  .item__image.hover & {
    opacity: 1;
  }
}
</style>

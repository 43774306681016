<template>
  <div
    class="item"
    :class="[{ 'item--media': size === 'small' }, 'item--id-' + item.id, 'item--' + size]"
  >
    <list-item-image
      :link="link"
      :hash="item.thumbnailRokkaHash"
      :size="size"
      :class="{ 'mrgl- sm-mrgr sm-mrgl0': size === 'small' }"
    />
    <list-item-content
      :title="item.title"
      :text="item.lead"
      :type="type"
      :technologies="technologies"
      :tasks="tasks"
      :links="item.links"
      :class="{ 'mrgt+': size !== 'small' }"
    />
  </div>
</template>

<script>
import ListItemImage from '@/components/ListItemImage.vue'
import ListItemContent from '@/components/ListItemContent.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

export default {
  components: {
    ListItemImage,
    ListItemContent,
    ResponsiveImage
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },

  computed: {
    hasDetails () {
      return this.size === 'featured' || this.size === 'large'
    },

    type () {
      if (!this.hasDetails) {
        return ''
      }

      return this.item.type === 'employee' ? 'Liip' : this.item.type
    },

    technologies () {
      if (!this.hasDetails) {
        return []
      }

      return this.item.technologies.data.map(v => v.title)
    },

    tasks () {
      if (!this.hasDetails) {
        return []
      }

      return this.item.tasks.split(',')
    }
  }
}
</script>

<style lang="scss">
.item {
  position: relative;
  transform-style: preserve-3d;
  .link-list {
    opacity: 0;
    transition: 0.3s;
  }
  &:hover .link-list {
    opacity: 1;
  }
}

.item--media {
  display: flex;
  align-items: flex-start;
}

.item--small {
  .item__image {
    order: 5;
    @include media('sm') {
      order: 0;
    }
  }
}

// drawmote
.item--id-2 {
  transform-origin: bottom left;
  transform-style: preserve-3d;
  .item-content {
    position: relative;
    z-index: 10;
  }
  @include media('md') {
    .item__image {
      transform: rotate3d(1, 1, 0, 18deg) translateZ(-20rem) translateX(6rem) translateY(-4rem);
      transform-style: preserve-3d;
      transform-origin: bottom right;
      &:after, &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #444;
        pointer-events: none;
      }
      &:before {
        transform-origin: top left;
        transform: rotateY(150deg);
      }
      &:after {
        transform-origin: top right;
        transform: rotateY(150deg);
      }
    }
  }
  .image--inverted img {
    filter: grayscale(100%) brightness(65%) contrast(240%) invert(100%);
    opacity: 0.4;
  }
}

// ASVZ
.item--id-4 .image--inverted img {
  filter: grayscale(100%) brightness(85%) contrast(280%) invert(0%);
  opacity: 0.15;
}

// Arbeitswelt
.item--id-5 .image--inverted img {
  filter: grayscale(100%) brightness(73%) contrast(270%) invert(0%);
  opacity: 0.15;
}

// SHKB
.item--id-6 .image--inverted img {
  filter: grayscale(100%) brightness(60%) contrast(280%) invert(0%);
  opacity: 0.2;
}

// Rokka
.item--id-8 .image--inverted img {
  filter: grayscale(100%) brightness(72%) contrast(260%) invert(0%);
  opacity: 0.3;
}

// Time for Coffee
.item--id-9 .image--inverted img {
  filter: grayscale(100%) brightness(88%) contrast(220%) invert(0%);
  opacity: 0.3;
}

// FREITAG
.item--id-7 .image--inverted img {
  filter: grayscale(100%) brightness(60%) contrast(220%) invert(100%);
  opacity: 0.3;
}

// JS
.item--id-11 .image--inverted img,
.item--id-12 .image--inverted img,
.item--id-13 .image--inverted img {
  filter: grayscale(100%) brightness(83%) contrast(300%) invert(100%);
}

// Drupal
.item--id-14 .image--inverted img {
  filter: grayscale(100%) brightness(64%) contrast(450%) invert(100%);
}

// Webpack
.item--id-15 .image--inverted img,
.item--id-16 .image--inverted img {
  filter: grayscale(100%) brightness(74%) contrast(300%) invert(100%);
}

// Water
.item--id-17 .image--inverted img {
  filter: grayscale(100%) brightness(53%) contrast(280%);
}

// CSS filter
.item--id-18 .image--inverted img {
  filter: grayscale(100%) brightness(123%) contrast(140%);
  opacity: 0.3;
}

// CSS 3d
.item--id-19 .image--inverted img {
  filter: grayscale(100%) brightness(123%) contrast(140%) invert(100%);
  opacity: 0.3;
}

// foldable map
.item--id-20 .image--inverted img {
  filter: grayscale(100%) brightness(60%) contrast(410%) invert(100%);
  opacity: 0.5;
}

// blend modes
.item--id-21 .image--inverted img {
  filter: grayscale(100%) brightness(250%) contrast(110%) invert(0%);
  opacity: 0.5;
}

.item--id-22 .image--inverted img {
  filter: grayscale(100%) brightness(130%) contrast(110%) invert(100%);
  opacity: 0.3;
}

/* .projects--large { */
/*   .grid__item { */
/*     &:nth-child(1) { */
/*       .item__image { */
/*         transform-origin: top left; */
/*         transform: rotate3d(-0,-1,0,-10deg); */
/*       } */
/*     } */
/*     &:nth-child(2) { */
/*       .item__image { */
/*         transform-origin: top left; */
/*         transform: rotate3d(-0,-1,0,10deg) translateZ(6rem) scale(0.8) translateY(5rem); */
/*       } */
/*     } */
/*   } */
/* } */

</style>

<template>
  <div class="fact flex">
    <div class="fact__aside">
      <div class="fact__image">
        <responsive-image :hash="fact.thumbnailRokkaHash" file-type="png" size="tiny" />
      </div>
    </div>
    <div class="fact__content">
      <h4 class="h3">{{ fact.title }}</h4>
      <div v-html="fact.text" />
    </div>
  </div>
</template>

<script>
import ResponsiveImage from '@/components/ResponsiveImage.vue'

export default {
  components: {
    ResponsiveImage
  },

  props: {
    fact: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.fact__image {
  border: 1px solid #ddd;
  border-radius: 100%;
  overflow: hidden;
  padding: 0.5rem;
  background: white;
  filter: grayscale(100%) contrast(80%) invert(100%);
}

.fact__aside {
  flex: 0 0 auto;
  display: none;
  @include media('sm') {
    display: block;
  }
  @include media('md') {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.fact__content {
  flex: 1;
}
</style>

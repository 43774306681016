<template>
  <section class="section">
    <div class="container">
      <slot name="heading" v-if="heading">
        <h2 class="section__title">
          <span>{{ heading }}</span>
        </h2>
      </slot>
      <div class="section__content">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss">
.container {
  max-width: 1376px;
  margin: 0 auto;
}

.section {
  padding: 0 $gutter-xs;
  margin: $gutter-xs auto;
  @include media('sm') {
    padding: 0 $gutter-sm;
    margin: $gutter-sm auto;
  }
  @include media('md') {
    padding: 0 $gutter-md;
    margin: $gutter-md auto;
  }
  @include media('lg') {
    padding: 0 $gutter-lg;
    margin: $gutter-lg auto;
  }
}

.section__title {
  @include media('md') {
    transform-origin: bottom left;
    /* transform: translateX(-100%) rotate(-90deg) translateY(-3rem) translateX(0rem); */
    position: sticky;
    top: 6rem;
    margin: 0;
    z-index: 10;
    transform-style: preserve-3d;
    transform: translateZ(6rem) translateX(6rem);
    span {
    }
  }
}

.section__content {
}
</style>
